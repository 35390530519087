import React, { useEffect, useState } from 'react';
import { LuTwitter } from "react-icons/lu";
import { BiLogoTelegram } from "react-icons/bi";
import cat1 from './../assets/cat1.jpeg';
import './contact.css';

function Contact() {
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    const triggerScroll = 200;

    if (scrollY > triggerScroll) {
      setShouldAnimate(true);
    } else {
      setShouldAnimate(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className='contact' id='contact'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-6'>
              <div className='header_content'>
                <div className='header_section'>
                <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
                  <p>
                  TheDogeCoin is a dive into the whimsical world of dogs, capturing the playful and curious spirit of these mysterious creatures in the crypto realm.
                  </p>
                  <div className='header_buttons'>
                    <a href='https://twitter.com/thedogecoinsol' className='btn link-outline' target='_blank'>
                      <LuTwitter />
                    </a>
                    <a href='https://t.me/thedogecoinsol' className='btn link-outline' target='_blank'>
                      <BiLogoTelegram />
                    </a>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-6'>
              <img src={cat1} alt='' className='contact-image'/>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Contact;
