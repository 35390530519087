import React, { useEffect, useState } from 'react';
import cat1 from './../assets/cat1.jpeg';
import { GiHamburgerMenu } from 'react-icons/gi';
 
const Navbar = () => {
  const [state,setState] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    const triggerScroll = 200;

    if (scrollY > triggerScroll) {
      setShouldAnimate(true);
    } else {
      setShouldAnimate(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <nav className='navbar'>
         <div className='container'>
           <div className='navbar_container'>
             <ul className='navbar_left'>
               <div className='navbar_left-logo'>
                 <img src={cat1} alt=''/>
               </div>
             </ul>
                {/* <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'} menu-link`}> */}
                <div className={state ? "menu-link mobile-menu-link" : "menu-link"}>
                 <ul className='navbar_right '>
                  <li><a href='#home'>Home</a></li>
                  <li><a href='#about'>About</a></li>
                  <li><a href='#token'>Tokenomics</a></li>
                  <li><a href='#road'>Roadmap</a></li>
                 </ul>
                 </div>
              <div className='hamburger-menu' >
                  <a href='#' onClick={()=>setState(!state)}>
                  <GiHamburgerMenu/>
                  </a>
              </div>
           </div>
         </div>
      </nav>
    </>
  );
};

export default Navbar;

