import React from 'react';
import { LuTwitter } from "react-icons/lu";
import { BiLogoTelegram } from "react-icons/bi";
import cat1 from './../assets/cat1.jpeg';

function Socialmedia() {
  return (
    <section className='socialmedia' id='social' >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-6'>
              <img src={cat1} alt='' className='social-media-image'/>
            </div>
            <div className='col-6'>
              <div className='header_content'>
                <div className='header_section'>
                  <h2>Follow TheDogeCoin on</h2>
                  <h1>social media</h1>
                  <div className='header_buttons'>
                    <a href='https://twitter.com/thedogecoinsol' className='btn link-outline' target='_blank'>
                      <LuTwitter />
                    </a>
                    <a href='https://t.me/thedogecoinsol' className='btn link-outline' target='_blank'>
                      <BiLogoTelegram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Socialmedia;
