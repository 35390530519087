import React, { useEffect,useState } from 'react'
import cat1 from './../assets/cat1.jpeg'

function About() {
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    const triggerScroll = 200;

    if (scrollY > triggerScroll) {
      setShouldAnimate(true);
    } else {
      setShouldAnimate(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className='about' id='about'>
        <div className='container'>
            <div className='row align-items-center'>
            <div className='col-6'>
                    <img src={cat1} alt='' className='about-image'/>
                </div>
                <div className='col-6'>
                    <div className='header_content'>
                        <div className='header_section'>
                            <h2>About TheDogeCoin </h2>
                            <div className={`about-cat text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
                            <p>
                                Many wonder, what is TheDogeCoin? TheDogeCoin is a dive into the whimsical world of dogs,
                                capturing the playful and curious spirit of these mysterious creatures in the crypto realm. 
                                Like a poetic tribute to our feline companions, this token dances on the blockchain stage, 
                                offering a symphony of fantasy and charm.
                            </p>
                            <p>
                              TheDogeCoin was created in response to this demand, and to provide value to the ecosystem the solana blockchain.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='header_about_content'>
                <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
                    <p>
                       TheDogeCoin will be more than just a memecoin, featuring several unique use cases and utilities that are beneficial to the long term growth of the Solana Ecosystem.
                    </p>
                    <p>
                       With the Solana blockchain and coin making a huge return over the last few months, it’s time for TheDogeCoin to come in and set the stardard for memecoins on Solana. There’s a new dog in town.
                    </p>
                    </div>
              </div>
        </div>
    </section>
  )
}

export default About