import Navbar from './components/Navbar';
import Header from './components/Header';
import './App.css';
import About from './pages/About';
import Roadmap from './pages/Roadmap';
import Socialmedia from './pages/Socialmedia';
import Footer from './components/Footer';
import Tokenomics from './pages/Tokenomics';
import Contact from './pages/Contact';
import './styles/Responsive.css';

const App = () => {
  return (
    <div className='App'>  
        <Navbar/> 
        <Header/> 
        <About/>
        <Tokenomics/>
        <Roadmap/>
        <Socialmedia/>
        <Contact/>
        <Footer/>
    </div>
  );
};

export default App;
