import React, { useEffect, useState } from 'react'
import { BsCopy } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Tokenomics() {
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    const triggerScroll = 200;

    if (scrollY > triggerScroll) {
      setShouldAnimate(true);
    } else {
      setShouldAnimate(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleCopyClick = () => {
    const tokenAddress = '2H8E2s9g5ayA4ezvFjxxdzNN5pyjjkNyXRfjzbMPexbD';

    // Create a textarea element dynamically
    const textarea = document.createElement('textarea');
    textarea.value = tokenAddress;

    //  Make it invisible
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Remove the textarea from the DOM
    document.body.removeChild(textarea);
    toast('Copy tocken !')

  };

  return (
    <section className="tokenomic" id='token'>
      <div className='token'>
              <h1 style={{ color:'#6f34fe'}}>Tokenomics</h1>
              </div>
        <div className='container'>
            <div class="row align-items-center smallscreen">
              <div class="col-12 token_class">
              <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
              <div className='token_size tokenaddress'>
                <p >Token Address</p>
                <p className='tokencode'>2H8E2s9g5ayA4ezvFjxxdzNN5pyjjkNyXRfjzbMPexbD <BsCopy onClick={handleCopyClick} tooltip="rybhrrh"/></p>
                </div>
              </div>
              </div>
            </div>

            <div class="row justify-content-between smallscreen">
              <div class="col-4 token_class">
               <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
               <div className='token_size'>
                <p>Supply</p>
                <p>500000000</p>
                </div>
                </div>
              </div>
              <div class="col-4 token_class">
              <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
              <div className='token_size'>
                <p>Symbol</p>
                <p>$ TDC</p>
                </div>
                </div>
              </div>
              <div class="col-4 token_class" >
              <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
              <div className='token_size'>
                <p>Decimal</p>
                <p>9</p>
                </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-between smallscreen">
            <div class="col-4 token_class" >
            <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
            <div className='token_size'>
                <p>50%</p>
                <p>Presale</p>
                </div>
                </div>
            </div>
            <div class="col-4 token_class" >
            <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
            <div className='token_size'>
                <p>20%</p>
                <p>LP</p>
                </div>
                </div>
            </div>
            <div class="col-4 token_class" >
            <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
            <div className='token_size'>
                <p>15%</p>
                <p>CEX Listings</p>
                </div>
                </div>
            </div>
            </div>

            <div class="row  justify-content-between smallscreen">
              <div class="col-6 token_class" >
              <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
              <div className='token_size'>
                <p>10%</p>
                <p>Marketing & Developement</p>
                </div>
                </div>
              </div>
              <div class="col-6 token_class" >
              <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
              <div className='token_size'>
                <p>5%</p>
                <p>Team</p>
                </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer/>
    </section>
  )
}

export default Tokenomics