import React from 'react';
import { Container } from 'react-bootstrap';
import { BiUpArrowAlt } from "react-icons/bi";
import { Link } from 'react-router-dom';
function Footer() {
  return (
    <div>
      <footer className="footer">
        <Container>
        <div className='footer_btn_up'>
        <p>&copy; {new Date().getFullYear()} <span>TheDogeCoin</span></p>
          <ul>
             <li><a href='#home'><BiUpArrowAlt /></a></li>
             </ul>
          </div>

        </Container>
      </footer>
    </div>
  )
}

export default Footer
