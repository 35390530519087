import React from "react";
import { LuTwitter } from "react-icons/lu";
import { BiLogoTelegram } from "react-icons/bi";
import cat1 from "./../assets/cat1.jpeg";

function Header() {
  return (
    <>
      <section className="header" id="home">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">
                <div className="header_section">
                  <h2>Welcome to </h2>
                  <h1>
                    <span>TheDoge</span>Coin
                  </h1>
                  <div className="header_buttons">
                    <a
                      href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=2H8E2s9g5ayA4ezvFjxxdzNN5pyjjkNyXRfjzbMPexbD&fixed=in"
                      className="btn btn-outline"
                      target="_blank"
                    >
                      Buy Now
                    </a>
                    <a
                      href="https://www.dextools.io/app/en/solana/pair-explorer/cPidbc9Nm6CWr5aM1RH2Qq1mh5cfQVBrz7fhYMkSRBD?t=1708088891271
"
                      className="btn btn-outline"
                      target="_blank"
                    >
                      Chart
                    </a>
                    <a
                      href="https://twitter.com/thedogecoinsol
"
                      className="btn link-outline"
                      target="_blank"
                    >
                      <LuTwitter />
                    </a>
                    <a
                      href="https://t.me/thedogecoinsol"
                      className="btn link-outline"
                      target="_blank"
                    >
                      <BiLogoTelegram />
                    </a>
                  </div>
                </div>
            </div>
            <div className="col-6 header-page">
              <img src={cat1} alt="" className="image" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
