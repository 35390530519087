import React, { useEffect, useState } from 'react'
import check from './../assets/check.svg';

function Roadmap() {
  const [shouldAnimate, setShouldAnimate] = useState(true);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    const triggerScroll = 200;

    if (scrollY > triggerScroll) {
      setShouldAnimate(true);
    } else {
      setShouldAnimate(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <section className="timeline" id='road'>
    
         <div className='roadmap'>
           <h1 style={{ color:'#6f34fe'}}>Roadmap</h1>
         </div>
      <div className='containerss right'>
     
      
               <div className='text-box'>  
               <div className='heading_phase'>
               <h3>Phase 1</h3>
               </div>
                <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
                    <p>Deploy contract<span><img style={{width:20}} src={check}/></span></p>
                    <p>Build community<span><img style={{width:20}} src={check}/></span></p>
                    <p>Presale<span><img style={{width:20}} src={check}/></span></p>
                    <p>Distri presale participants<span><img style={{width:20}} src={check}/></span></p>
                    <span className='right-arrao'></span>
                    </div>
                    </div>
       
      </div>
      <div className='containerss left'>      
        
                <div className='text-box'> 
                <div className='heading_phase'>
               <h3>Phase 2</h3>
               </div>           
                <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
                    <p>Roadmap<span><img style={{width:20}} src={check}/></span></p>
                    <p>Launch on raydium<span><img style={{width:20}} src={check}/></span></p>
                    <p>Influencer and artist<span><img style={{width:20}} src={check}/></span></p>
                    <p>1000 holders</p>
                    <span className='left-arrao'></span>
                    </div>
                    </div>
                
      </div>
      <div className='containerss right'>
        
                
                <div className='text-box'>  
                <div className='heading_phase'>
               <h3>Phase 3</h3>
               </div>
                <div className={`text ${shouldAnimate ? 'animate-up' : 'animate-down'}`}>
                    <p>Listing on CG</p>
                    <p>Listing on CMC</p>
                    <p>More influencer</p>
                    <p>Social media expansion</p>
                    <p>5000 holders</p>
                    <p>CEX listing</p>
                    <span className='right-arrao'></span>
                    </div>
                    </div>
               
      </div>
    </section>
  )
}

export default Roadmap